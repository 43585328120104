import { API_URL } from '../../../app-config/constants';
import { get, post, remove } from '../request';
const BASE_URL = `${API_URL}/copilot/history`;
getHistory.operation = 'READ';
export function getHistory() {
    return get(BASE_URL);
}
getChat.operation = 'READ';
export function getChat(id) {
    return get(`${BASE_URL}/${id}`);
}
rateAiResponse.operation = 'COMMAND';
export function rateAiResponse(id, historyItemId, userRating) {
    return post(`${BASE_URL}/${id}/${historyItemId}`, { postData: userRating });
}
deleteChat.operation = 'DELETE';
export function deleteChat(id) {
    return remove(`${BASE_URL}/${id}`);
}
getCompanyObserverHistory.operation = 'READ';
export function getCompanyObserverHistory(companyId) {
    return get(`${API_URL}/ai-observer/company/${companyId}/chat-history`);
}
getUserObserverHistory.operation = 'READ';
export function getUserObserverHistory(userId) {
    return get(`${API_URL}/ai-observer/user/${userId}/chat-history`);
}
