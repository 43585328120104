import { API_URL } from '../../../app-config/constants';
import { toFilePayload } from '../../react/features/Attachments/service';
import { get, post, put, remove } from '../request';
const RESOURCE = `${API_URL}/users`;
getById.operation = 'READ';
// getById.byId = true; // Disabled for now. invalidates() below doesn't work when this is set
export function getById(id) {
    return get(`${RESOURCE}/${id}`);
}
getByIds.operation = 'READ';
// getByIds.byIds = true; // Disabled for now. invalidates() below doesn't work when this is set
export function getByIds(ids) {
    return get(`${RESOURCE}/by-ids?ids=${ids.join(',')}`);
}
updateUserWithPassword.operation = 'COMMAND';
export function updateUserWithPassword(user, password) {
    return put(RESOURCE + '/' + user.id, {
        postData: user,
        headers: { 'Si-User-Password': password },
    });
}
updateUserWithoutPassword.operation = 'COMMAND';
export function updateUserWithoutPassword(user) {
    return put(RESOURCE + '/' + user.id, { postData: user });
}
updateRoles.operation = 'COMMAND';
export function updateRoles(userId, roles) {
    return put(`${RESOURCE}/${userId}/roles`, { postData: roles });
}
changeTwoFactorVerification.operation = 'COMMAND';
export function changeTwoFactorVerification(userId, required) {
    return put(`${RESOURCE}/${userId}/2fa?required=${required.toString()}`);
}
resetTwoFactorVerification.operation = 'COMMAND';
export function resetTwoFactorVerification(userId) {
    return put(`${RESOURCE}/${userId}/2fa/reset`);
}
deleteUser.operation = 'DELETE';
export function deleteUser(userId, thenHandlerToExecuteBeforeLaddaStateUpdate = () => { }) {
    return remove(`${RESOURCE}/${userId}`).then(thenHandlerToExecuteBeforeLaddaStateUpdate);
}
export function clearPassword(userId) {
    return remove(`${RESOURCE}/${userId}/password`);
}
export function sendPasswordMail(userId) {
    return post(`${RESOURCE}/${userId}/mail/password-reset`);
}
export function sendWelcomeMail(userId) {
    return post(`${RESOURCE}/${userId}/mail/welcome`);
}
export function sendProfileNudge(userId) {
    return post(`${RESOURCE}/${userId}/mail/profile-nudge`);
}
export function impersonate(userId) {
    return post(`${RESOURCE}/${userId}/impersonate`);
}
addAttachment.operation = 'COMMAND';
export function addAttachment(userId, file, isProtected) {
    return post(`${RESOURCE}/${userId}/attachments`, {
        postData: Object.assign(Object.assign({}, toFilePayload(file)), { isProtected: isProtected }),
    });
}
deleteAttachment.operation = 'COMMAND';
/**
 * @param {string} userId
 * @param {string} attachmentId
 * @returns {Promise<any>}
 */
export function deleteAttachment(userId, attachmentId) {
    return remove(`${RESOURCE}/${userId}/attachments/${attachmentId}`);
}
updateManager.operation = 'COMMAND';
export function updateManager(userId, managerId) {
    if (managerId === null) {
        return put(`${RESOURCE}/${userId}/manager`);
    }
    return put(`${RESOURCE}/${userId}/manager?managerId=${managerId}`);
}
updateSecondaryReviewer.operation = 'COMMAND';
export function updateSecondaryReviewer(userId, secondaryReviewerId) {
    if (secondaryReviewerId === null) {
        return put(`${RESOURCE}/${userId}/secondary-reviewer`);
    }
    return put(`${RESOURCE}/${userId}/secondary-reviewer?secondaryReviewerId=${secondaryReviewerId}`);
}
invalidate.operation = 'NO_OPERATION';
invalidate.invalidates = ['getById', 'getByIds'];
export function invalidate() {
    return Promise.resolve();
}
