import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const REVIEW_CYCLE_V2_PATH = `${API_URL}/review-cycle`;
getCycles.operation = 'READ';
export function getCycles(reviewerIds) {
    return get(REVIEW_CYCLE_V2_PATH + '?' + encodeData(reviewerIds));
}
function encodeData(data) {
    return Object.keys(data)
        .map(key => [key, data[key]].map(encodeURIComponent).join('='))
        .join('&');
}
