import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const BASE_URL = `${API_URL}/copilot`;
copilotPrompt.operation = 'COMMAND';
export function copilotPrompt(id, type, input) {
    return post(BASE_URL, { postData: { id, type, input } });
}
getReportSummary.operation = 'NO_OPERATION';
export function getReportSummary() {
    return get(`${BASE_URL}/report-summary`);
}
getQuotaTokens.operation = 'NO_OPERATION';
export function getQuotaTokens() {
    return get(`${BASE_URL}/tokens`);
}
